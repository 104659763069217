console.log('Ready javascript');

// Mobile > all touchstar DOM perfect Iphone
document.addEventListener("touchstart", function(){}, true);


$('.js-eventClick__plus-info-content').click(function(){
  $(this).hide();
  $('.js-plus-info-content').fadeIn();
});
$('.js-eventClick__plus-info-content-2').click(function(){
  $(this).hide();
  $('.js-plus-info-content-2').fadeIn();
});

// Top Scroll button element
// $('body').materialScrollTop();

// // anchor link
$(document).on('click', 'a[href^="#"]', function (event) {
  event.preventDefault();

  $('html, body').animate({
    scrollTop: $($.attr(this, 'href')).offset().top
  }, 500);
});




